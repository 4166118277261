.error-404
  --header-col-gap calc(var(--def-margin) * 1.5)
  --icon-font-size unit(190/16, rem)
  --icon-font-weight 800
  --icon-line-height 1
  --main-clr-fg var(--clr-red-100)
  --subtitle-font-size unit(50/16, rem)
  --subtitle-font-weight 800
  --subtitle-line-height 1
  --text-font-size unit(35/16, rem)
  --text-font-weight 800
  --text-line-height (46/35)
  --title-font-size unit(105/16, rem)
  --title-font-weight 800
  --title-line-height 1
  color var(--main-clr-fg)
  +until($MOBILE_SIZE)
    --icon-font-size unit(95/16, rem)
    --subtitle-font-size unit(25/16, rem)
    --text-font-size unit(17.5/16, rem)
    --title-font-size unit(52.5/16, rem)
  +until(374px)
    --icon-font-size unit(64/16, rem)
    --header-col-gap unit(16/16, rem)
    --subtitle-font-size unit(22/16, rem)
    --title-font-size unit(40/16, rem)

.error-404
  align-items center
  display flex
  flex-direction column

.error-404__header
  align-items flex-end
  display inline-grid
  gap var(--header-col-gap)
  grid-template-columns auto auto

.error-404__icon
  font-size var(--icon-font-size)
  font-weight var(--icon-font-weight)
  line-height var(--icon-line-height)

.error-404__subtitle
  font-size var(--subtitle-font-size)
  font-weight var(--subtitle-font-weight)
  line-height var(--subtitle-line-height)
  border-top unit(5/16, rem) solid

.error-404__text
  font-size var(--text-font-size)
  font-weight var(--text-font-weight)
  line-height var(--text-line-height)

.error-404__title
  font-size var(--title-font-size)
  font-weight var(--title-font-weight)
  line-height var(--title-line-height)

.error-404__wrapper
  align-items center
  display flex
  flex-direction column