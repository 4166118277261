:where(.main-search)
  --text-clr-bg @css { hsl(0, 0%, 0%, .48) }
  --text-clr-fg var(--clr-white)
  --text-height unit(31/16, rem)
  --text-padding-inline var(--def-margin)
  --text-width unit(214/16, rem)
  max-width var(--text-width)
  position relative

.main-search__submit
  aspect-ratio 1
  background-color unset
  border unset
  color var(--text-clr-fg)
  cursor pointer
  height var(--text-height)
  position absolute
  right calc(var(--text-height) * .25)
  top 0
  width auto

.main-search__text
  -placeholder(var(--text-clr-fg))
  background-color var(--text-clr-bg)
  border unset
  border-radius unit(8/16, rem)
  color var(--text-clr-fg)
  font-size unit(16/16, rem)
  padding-block unit(5/16, rem)
  padding-inline-end calc(var(--text-height) * 1.47)
  padding-inline-start var(--text-padding-inline)
  width @css { min(var(--text-width), 100%) }