.main-header-mobile-search
  position relative

.main-header-mobile-search__button
  font-size unit(18/16, rem)

.main-header-mobile-search__form
  --text-clr-bg var(--clr-red-300)
  --text-width unit(160/16, rem)
  opacity 0
  pointer-events none
  position absolute
  right unit(-15/16, rem)
  top 50%
  transform translateY(-50%)
  transition var(--def-transition)
  transition-property opacity
  width var(--text-width)

.main-header-mobile-search__form .main-search__submit
  font-size unit(18/16, rem)
  top 1px

html.has-mobile-search-showed .main-header-mobile-search__form
  opacity 1
  pointer-events auto