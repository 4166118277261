.main-live-box-nav
  --main-clr-fg @css { hsl(180, 3%, 85%) }
  --main-font-size unit(25/16, rem)
  --main-font-weight 900
  display flex
  flex-wrap wrap
  font-size var(--main-font-size)
  font-weight var(--main-font-weight)
  gap 1rem

.main-live-box-nav__item
  border-bottom unit(4/16, rem) solid
  color var(--main-clr-fg)
  transition var(--def-transition)
  transition-property color

.main-live-box-nav__item:is(.is-actived)
  --main-clr-fg var(--clr-red-100)