:root
  --alt-font-family 'Raleway', sans-serif
  --banner-width 300px
  --clr-agricultura-tv @css { hsl(20, 27%, 2%) } // #070504
  --clr-black @css { hsl(0, 0%, 0%) } // #000
  --clr-black-alpha @css { color-mix(in srgb, var(--clr-black), transparent 63%) }
  --clr-black-alpha-hover @css { color-mix(in srgb, var(--clr-black), transparent 53%) }
  --clr-deportes @css { hsl(197, 77%, 12%) } // #072936
  --clr-entretencion @css { hsl(178, 44%, 55%) } // #5ABFBC
  --clr-gray-100 @css { hsl(180, 3%, 85%) } // #D8DADA
  --clr-opinion @css { hsl(356, 83%, 58%) } // #ED3A47
  --clr-red--100 @css { hsl(356, 90%, 55%) } // #F42534
  --clr-red-100 @css { hsl(356, 78%, 55%) } // #E6313C
  --clr-red-200 @css { hsl(356, 83%, 51%) } // #EA1B2A
  --clr-red-300 @css { hsl(350, 99%, 47%) } // #EC0127
  --clr-white @css { hsl(0, 0%, 100%) } // #FFF
  --def-body-bg @css { hsl(0, 0%, 100%) }
  --def-fixed-player-shadow 0 0 unit(16/16, rem) @css{ hsl(0, 0%, 0%, .4) }, 0 0 unit(2/16, rem) @css{ hsl(0, 0%, 0%, .5) }
  --def-font-family 'Roboto Slab', serif
  --def-line-height 1.48
  --def-margin 1rem
  --def-obj-pos center
  --def-selection-bg var(--clr-red-200)
  --def-selection-fg @css { hsl(0, 0%, 100%) }
  --def-text-color var(--clr-black)
  --def-transition 300ms ease-out
  --def-width unit(1300/16, rem)
  --idx-embed-fixed calc(var(--idx-main-header) - 10)
  --idx-main-header 100