.the-footer
  --main-clr-fg var(--clr-red-100)
  --main-font-size unit(20/16, rem)
  --main-font-weight 800
  --main-line-height (39/30)
  color var(--main-clr-fg)
  display block
  font-size var(--main-font-size)
  font-weight var(--main-font-weight)
  line-height var(--main-line-height)
  border-bottom unit(2/16, rem) solid
  padding-block-end unit(6/16, rem)