.main-anchor-list
  --main-clr-fg var(--clr-red-100)
  --main-font-size unit(18/16, rem)
  --main-font-weight 700
  color var(--main-clr-fg)
  font-size var(--main-font-size)
  font-weight var(--main-font-weight)
  width 100%
  border-bottom unit(3/16, rem) solid
  padding-block-end unit(8/16, rem)

.main-anchor-list__body
  display flex
  gap var(--def-margin)
  justify-content space-between
  overflow-x auto

.main-anchor-list__item
  flex-shrink 0