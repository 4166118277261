.user-card
  --date-font-size unit(20/16, rem)
  --date-font-weight 500
  --subtitle-font-size unit(34/16, rem)
  --subtitle-font-weight 600
  --title-font-size unit(35/16, rem)
  --title-font-weight 700
  display flex
  flex-direction column
  gap unit(36/16, rem)
  text-align center

.user-card--mobile-small
  --date-font-size unit(14/16, rem)
  --subtitle-font-size unit(24/16, rem)
  --title-font-size unit(20/16, rem)

.user-card__caption
  display flex
  flex-direction column

.user-card__date
  font-size var(--date-font-size)
  font-weight var(--date-font-weight)

.user-card__image
  left 0
  object-fit cover
  object-position var(--def-obj-pos)
  position absolute
  size 100%
  top 0

.user-card__media
  aspect-ratio 1
  border-radius 50%
  display block
  height auto
  overflow hidden
  position relative
  width 100%

.user-card__text
  margin-block unset
  text-align start

.user-card__subtitle
  font-size var(--subtitle-font-size)
  font-weight var(--subtitle-font-weight)
  line-height 1.3

.user-card__title
  font-size var(--title-font-size)
  font-weight var(--title-font-weight)