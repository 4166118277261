.most-viewed-box
  --footer-font-size unit(16/16, rem)
  --footer-font-weight 600

.most-viewed-box__button:is(:hover)
  text-decoration underline

.most-viewed-box__footer
  font-size var(--footer-font-size)
  font-weight var(--footer-font-weight)
  text-align end