.main-fixed-player
  --main-clr-bg var(--clr-black)
  --main-offset var(--def-margin)
  --main-size unit(304/16, rem)
  background-color var(--main-clr-bg)
  bottom var(--main-offset)
  box-shadow var(--def-fixed-player-shadow)
  max-width var(--main-size)
  position fixed
  right var(--main-offset)
  width 100%
  z-index var(--idx-embed-fixed)
  +until($MOBILE_SIZE)
    --main-size unit(248/16, rem)

.main-fixed-player__button
  aspect-ratio 1
  background-color var(--clr-red-300)
  border-radius 50%
  color var(--clr-white)
  cursor pointer
  display grid
  font-size unit(18/16, rem)
  height auto
  line-height 1
  place-items center
  position absolute
  right 0
  top 0
  translate 35% -35%
  width 1.5em