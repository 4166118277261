.main-accordion
  --main-body-size 0fr
  --main-icon-turn 0turn
  --main-transition var(--def-transition)

.main-accordion__body
  display grid
  grid-template-rows var(--main-body-size)
  transition grid-template-rows var(--main-transition)

.main-accordion__icon
  rotate var(--main-icon-turn)
  transition rotate var(--main-transition)

.main-accordion__wrapper
  overflow hidden

.main-accordion:is(.is-showed)
  --main-body-size 1fr
  --main-icon-turn .5turn