:where(html)
  -webkit-text-size-adjust 100%
  line-height 1.15

:where(body)
  margin 0

:where(main)
  display block

:where(h1)
  font-size 2em
  margin .67em 0

:where(hr)
  box-sizing content-box
  height 0
  overflow visible

:where(pre)
  font-family monospace, monospace
  font-size 1em

:where(a)
  background-color transparent

:where(abbr[title])
  border-bottom none
  text-decoration underline
  text-decoration underline dotted

:where(b, strong)
  font-weight bolder

:where(code, kbd, samp)
  font-family monospace, monospace
  font-size 1em

:where(small)
  font-size 80%

:where(sub, sup)
  font-size 75%
  line-height 0
  position relative
  vertical-align baseline

:where(sub)
  bottom -.25em

:where(sup)
  top -.5em

:where(img)
  border-style none

:where(button, input, optgroup, select, textarea)
  font-family inherit
  font-size 100%
  line-height 1.15
  margin 0

:where(button, input)
  overflow visible

:where(button, select)
  text-transform none

:where(button, [type="button"], [type="reset"], [type="submit"])
  -webkit-appearance button

:where(button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner)
  border-style none
  padding 0

:where(button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring)
  outline 1px dotted ButtonText

:where(fieldset)
  padding .35em .75em .625em

:where(legend)
  box-sizing border-box
  color inherit
  display table
  max-width 100%
  padding 0
  white-space normal

:where(progress)
  vertical-align baseline

:where(textarea)
  overflow auto

:where([type="checkbox"], [type="radio"])
  box-sizing border-box
  padding 0

:where([type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button)
  height auto

:where([type="search"])
  -webkit-appearance textfield
  outline-offset -2px

:where([type="search"]::-webkit-search-decoration)
  -webkit-appearance none

:where(::-webkit-file-upload-button)
  -webkit-appearance button
  font inherit

:where(details)
  display block

:where(summary)
  display list-item

:where(template)
  display none

:where([hidden])
  display none