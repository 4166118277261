.most-viewed-box-card
  --grid-areas "title media"
  --grid-columns 1fr var(--image-width)
  --image-width unit(120/16, rem)
  --title-font-size 1rem
  --title-font-weight 500
  --title-line-height (20/16)
  align-items center
  display grid
  gap unit(12/16, rem)
  grid-template-areas var(--grid-areas)
  grid-template-columns var(--grid-columns)

.most-viewed-box-card--reverse
  --grid-areas "media title"
  --grid-columns var(--image-width) 1fr

.most-viewed-box-card__media
  grid-area media

.most-viewed-box-card__title
  font-size var(--title-font-size)
  font-weight var(--title-font-weight)
  grid-area title
  line-height var(--title-line-height)
