.main-media-box
  --body-clr-bg @css { hsl(0, 0%, 80%, .25) }
  --header-clr-bg var(--clr-red-100)
  --header-clr-fg var(--clr-white)
  --wrapper-offset var(--def-margin)
  --wrapper-size unit(304/16, rem)

.main-media-box--smaller
  max-width unit(300/16, rem)
  margin-inline auto

.main-media-box__body
  background-color var(--body-clr-bg)

.main-media-box__button
  aspect-ratio 1
  background-color var(--clr-red-300)
  border-radius 50%
  color var(--clr-white)
  cursor pointer
  display grid
  font-size unit(18/16, rem)
  height auto
  line-height 1
  opacity 0
  place-items center
  pointer-events none
  position absolute
  right 0
  top 0
  transition var(--def-transition)
  transition-property opacity
  translate 35% -35%
  width 1.5em

.main-media-box__header
  align-items center
  background-color var(--header-clr-bg)
  color var(--header-clr-fg)
  display flex
  justify-content space-between
  line-height 1
  padding-block unit(6/16, rem)
  padding-inline unit(20/16, rem)

.main-media-box__subtitle
  font-size unit(16/16, rem)
  font-weight 400

.main-media-box__title
  align-items baseline
  display flex
  font-family var(--alt-font-family)
  font-size unit(14/16, rem)
  font-weight 400
  gap .2em

.main-media-box__wrapper
  position relative

.main-media-box.has-embed-fixed:not(.has-disabled)
  .main-media-box__button
    opacity 1
    pointer-events auto
  .main-media-box__wrapper
    bottom var(--wrapper-offset)
    box-shadow var(--def-fixed-player-shadow)
    max-width var(--wrapper-size)
    position fixed
    right var(--wrapper-offset)
    width 100%
    z-index var(--idx-embed-fixed)