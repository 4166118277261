// BLOCK MIXINS
from($size, $orientation = 'width')
  @media (min-{$orientation} $size)
    {block}

until($size, $orientation = 'width')
  @media (max-{$orientation} $size)
    {block}

between($min, $max, $orientation = 'width')
  @media (min-{$orientation} $min) and (max-{$orientation} $max)
    {block}

retina()
  @media
  only screen and (-webkit-min-device-pixel-ratio 1.25),
  only screen and (min--moz-device-pixel-ratio 1.25),
  only screen and (-o-min-device-pixel-ratio 1.25/1),
  only screen and (min-device-pixel-ratio 1.25),
  only screen and (min-resolution 200dpi),
  only screen and (min-resolution 1.25dppx)
    {block}

portrait($width = 500px)
  @media (max-width $width) and (orientation portrait)
    {block}

landscape($width = 500px)
  @media (max-width $width) and (orientation landscape)
    {block}