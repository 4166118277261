// MARK: .main-header
.main-header
  left 0
  position sticky
  top 0
  z-index var(--idx-main-header)

.main-header__submenu
  position absolute
  right 0
  top 100%

// MARK: .main-header-button
.main-header-button
  background-color unset
  border unset
  color currentColor
  cursor pointer
  font-size unit(22/16, rem)
  padding unset

// MARK: .main-header-submenu
.main-header-submenu
  --main-clr-bg var(--clr-gray-100)
  --main-clr-fg var(--clr-red-300)
  --main-gap unit(12/16, rem)
  --main-padding unit(24/16, rem)
  --main-width unit(350/16, rem)
  align-items flex-start
  background-color var(--main-clr-bg)
  color var(--main-clr-fg)
  display flex
  flex-direction column
  font-size unit(20/16, rem)
  font-weight 500
  gap var(--main-gap)
  line-height 1.25
  max-width var(--main-width)
  opacity 0
  padding var(--main-padding)
  pointer-events none
  transform translateY(calc(var(--def-margin) * 3))
  transition var(--def-transition)
  transition-property transform, opacity
  width 100%

.main-header-submenu__button
  background-color var(--clr-red-200)
  color var(--clr-white)
  font-weight 500
  line-height 1
  max-width unit(247/16, rem)
  padding unit(6/16, rem)
  text-align center
  width 100%

.main-header-submenu-list
  display flex
  flex-direction column
  gap var(--main-gap)
  width 100%

.main-header-submenu-list__body
  display grid
  grid-template-rows 0fr
  transition var(--def-transition)
  transition-property grid-template-rows

.main-header-submenu-list__button
  cursor pointer
  font-size unit(24/16, rem)
  position absolute
  right 0
  top 0
  transition var(--def-transition)
  transition-property transform
  z-index 10

.main-header-submenu-list__item
  align-items flex-start
  display flex
  flex-direction column
  position relative

.main-header-submenu-list__wrapper
  padding-block-start var(--main-gap)

.main-header-submenu-list__item:is(.is-showed) .main-header-submenu-list__body
  grid-template-rows 1fr

.main-header-submenu-list__item:is(.is-showed) .main-header-submenu-list__button
  transform rotate(180deg)

.main-header-submenu-list__wrapper
  display flex
  flex-direction column
  gap var(--main-gap)

.main-header-submenu-socials
  align-item center
  display flex
  gap unit(16/16, rem)

// MARK: .main-header-superior
.main-header-superior
  --main-clr-bg var(--clr-red-200)
  --main-clr-fg var(--clr-white)
  --main-font-size unit(16/16, rem)
  background-color var(--main-clr-bg)
  color var(--main-clr-fg)
  font-size var(--main-font-size)
  line-height 1
  padding-block calc(var(--def-margin) / 2)

.main-header-superior__container
  align-items center
  display flex
  gap var(--def-margin)

.main-header-superior__links
  margin-inline-start auto

// MARK: .main-header-group
.main-header-group
  --main-gap unit(40/16, rem)
  align-items center
  display flex
  gap var(--main-gap)

// MARK: .main-header-links
.main-header-superior-links
  column-gap unit(44/16, rem)
  display flex
  flex-wrap wrap
  row-gap calc(var(--def-margin) / 2)

// MARK: .main-header-menu
.main-header-menu
  align-items cener
  column-gap var(--main-gap)
  display flex
  flex-wrap wrap
  font-size unit(18/16, rem)
  font-weight 500
  row-gap calc(var(--def-margin) / 3)

// MARK: .main-header-superior-socials
.main-header-superior-socials
  --main-font-size unit(20/16, rem)
  align-items center
  display flex
  font-size var(--main-font-size)
  gap calc(var(--def-margin) * 1.25)

// MARK: .main-header-wrapper
.main-header-wrapper
  --main-clr-bg var(--clr-red--100)
  --main-clr-fg var(--clr-white)
  background-color var(--main-clr-bg)
  color var(--main-clr-fg)

.main-header-wrapper__container
  align-items center
  display flex
  gap var(--def-margin)
  justify-content space-between
  position relative

html.has-main-header-submenu-showed .main-header-submenu
  opacity 1
  pointer-events auto
  transform translateY(0)

.main-header
  animation-name box-shadow
  animation-timeline scroll(root)

@keyframes box-shadow
  0%
    box-shadow 0 0 1rem transparent
  100%
    box-shadow 0 0 1rem @css { hsl(0, 0%, 0%, .6) }