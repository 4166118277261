.main-article
  --bag-clr-bg var(--clr-red-100)
  --bag-clr-bg--hover @css { color-mix(in srgb, var(--bag-clr-bg), black 15%) }
  --bag-clr-fg var(--clr-white)
  --bag-font-size unit(18/16, rem)
  --permalink-clr-fg var(--clr-red-200)
  --permalink-font-weight 700
  --socials-clr-fg var(--clr-red-200)
  --socials-clr-fg--hover @css { color-mix(in srgb, var(--clr-red-200), black 15%) }
  --socials-font-size unit(22/16, rem)
  --text-font-size unit(20/16, rem)
  --text-line-height (28/20)
  --title-font-size unit(38/16, rem)
  --title-line-height (50/38)
  --excerpt-font-size unit(20/16, rem)
  --excerpt-line-height (28/20)

.main-article__bag
  background-color var(--bag-clr-bg)
  color var(--bag-clr-fg)
  display inline-block
  font-size var(--bag-font-size)
  font-weight 700
  line-height 1
  padding .62em 1.1em
  transition var(--def-transition)
  transition-property background-color

.main-article__bag:is(:hover, :focus-visible)
  background-color var(--bag-clr-bg--hover)

.main-article__excerpt
  margin-block unset
  font-size var(--excerpt-font-size)
  line-height var(--excerpt-line-height)

.main-article__header
  align-items flex-start
  display flex
  flex-direction column
  gap var(--def-margin)

.main-article__title
  font-size var(--title-font-size)
  line-height var(--title-line-height)

// MARK: .main-article-box
.main-article-box
  --card-columns var(--card-image-width) 1fr
  --card-gap unit(20/16, rem)
  --card-image-width unit(300/16, rem)
  --card-line-height (32/24)
  --card-text-font-size unit(20/16, rem)
  --card-text-line-height (28/20)
  --card-title-font-size unit(24/16, rem)
  --title-clr-fg var(--clr-red-300)
  --title-font-size unit(28/16, rem)
  --title-font-weight 700
  --title-line-height 1

.main-article-box__title
  color var(--title-clr-fg)
  font-size var(--title-font-size)
  font-weight var(--title-font-weight)
  line-height var(--title-line-height)

.main-article-box-card
  display grid
  gap var(--card-gap)
  grid-template-columns var(--card-columns)
  align-items center

.main-article-box-card--alt
  grid-template-columns 1fr

.main-article-box-card__caption
  display flex
  flex-direction column
  align-items flex-start
  gap unit(8/16, rem)

.main-article-box-card__permalink
  transition color var(--def-transition)

.main-article-box-card__permalink:is(:hover, :focus-visible)
  color var(--title-clr-fg)

.main-article-box-card__text
  margin-block unset
  font-size var(--card-text-font-size)
  line-height var(--card-text-line-height)

.main-article-box-card__title
  font-size var(--card-title-font-size)
  line-height var(--card-line-height)

// MARK: .main-article-media
.main-article-media
  --main-clr-fg @css { hsl(0, 0%, 37%) }
  --main-font-size unit(18/16, rem)
  color var(--main-clr-fg)
  font-size var(--main-font-size)
  position relative

.main-article-media__footer
  text-align end

// MARK: .main-article-socials
.main-article-socials
  align-items center
  color var(--socials-clr-fg)
  display flex
  font-size var(--socials-font-size)
  gap 1ch
  justify-content flex-end

.main-article-socials__item
  transition var(--def-transition)
  transition-property color

.main-article-socials__item:is(:hover, :focus-visible)
  color var(--socials-clr-fg--hover)

// MARK: .main-article-text
.main-article-text
  font-size var(--text-font-size)
  line-height var(--text-line-height)

.main-article-text > p a
  color var(--permalink-clr-fg)
  font-weight var(--permalink-font-weight)
  text-decoration underline

.main-article-text > *:first-child
  margin-block-start unset

.main-article-text > *:last-child
  margin-block-end unset

// MARK: .main-article-tags
.main-article-tags
  --title-clr-fg var(--clr-red-300)
  --title-font-size unit(24/16, rem)
  --title-font-weight 700
  --title-line-height (28/24)
  --body-gap var(--def-margin)
  --item-clr-bg var(--clr-red-300)
  --item-clr-bg--hover @css { color-mix(in srgb, var(--clr-red-300), black 15%) }
  --item-clr-fg var(--clr-white)
  --item-font-size unit(18/16, rem)
  --item-font-weight 700
  --item-width unit(134/16, rem)

.main-article-tags__body
  display flex
  flex-wrap wrap
  gap var(--body-gap)

.main-article-tags__item
  background-color var(--item-clr-bg)
  color var(--item-clr-fg)
  font-weight var(--item-font-weight)
  padding .2em .75em
  min-width var(--item-width)
  text-align center
  transition background-color var(--def-transition)

.main-article-tags__item:is(:hover, :focus-visible)
  background-color var(--item-clr-bg--hover)

.main-article-tags__title
  color var(--title-clr-fg)
  font-size var(--title-font-size)
  font-weight var(--title-font-weight)
  line-height var(--title-line-height)

// MARK: .main-article-comments
.main-article-comments
  --header-border-radius unit(12/16, rem)
  --header-clr-bg var(--clr-red-300)
  --header-clr-bg--hover @css { color-mix(in srgb, var(--clr-red-300), black 15%) }
  --header-clr-fg var(--clr-white)
  --header-font-size unit(22/16, rem)
  --header-font-weight 500
  --header-padding-block unit(7/16, rem)
  --header-padding-inline unit(24/16, rem)
  --icon-font-size unit(24/16, rem)
  --icon-rotate 0turn
  --wrapper-rows 0fr
  user-select none

.main-article-comments:is(.is-showed)
  --wrapper-rows 1fr
  --icon-rotate .5turn

.main-article-comments__header
  align-items center
  background-color var(--header-clr-bg)
  border-radius var(--header-border-radius)
  color var(--header-clr-fg)
  cursor pointer
  display flex
  font-size var(--header-font-size)
  font-weight var(--header-font-weight)
  justify-content space-between
  padding-block var(--header-padding-block)
  padding-inline var(--header-padding-inline)

.main-article-comments__header:is(:active)
  scale .99

.main-article-comments__icon
  font-size var(--icon-font-size)
  rotate var(--icon-rotate)
  transition rotate var(--def-transition)

.main-article-comments__wrapper
  display grid
  grid-template-rows var(--wrapper-rows)
  transition grid-template-rows var(--def-transition)

// MARK: .main-article-info
.main-article-info
  width 100%

.main-article-info__wrapper
  display flex
  align-items center
  justify-content space-between
  gap var(--def-margin)

// MARK: .main-article-nav
.main-article-nav
  --header-font-size unit(20/16, rem)
  --header-font-weight 700
  --icon-font-size unit(26/16, rem)
  --main-clr-fg var(--clr-red-300)
  --menu-font-size unit(18/16, rem)
  --menu-font-weight 500
  color var(--main-clr-fg)
  width 100%

.main-article-nav__content
  margin-block-start var(--def-margin)

.main-article-nav__header
  align-items center
  border-bottom unit(2/16, rem) solid
  display flex
  font-size var(--header-font-size)
  font-weight var(--header-font-weight)
  gap var(--def-margin)
  justify-content space-between
  line-height 1
  padding-block-end unit(6/16, rem)

.main-article-nav__icon
  font-size var(--icon-font-size)

.main-article-nav__menu
  display flex
  flex-direction column
  font-size var(--menu-font-size)
  font-weight var(--menu-font-weight)
  gap unit(18/16, rem)
  line-height 1