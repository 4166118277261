// MARK: .main-footer-player
.main-footer-player
  --button-font-size unit(43/16, rem)
  --main-clr-bg var(--clr-red-200)
  --main-clr-fg var(--clr-white)
  --main-padding-inline unit(94/16, rem)
  background-color var(--main-clr-bg)
  color var(--main-clr-fg)
  padding-block unit(12/16, rem)

.main-footer-player__button
  background-color unset
  border unset
  color inherit
  cursor pointer
  font-size var(--button-font-size)
  padding unset

.main-footer-player__container
  align-items center
  display grid
  gap var(--def-margin)
  grid-template-columns 1fr auto 1fr
  padding-inline var(--main-padding-inline)

.main-footer-player__item--2
  display flex
  justify-content flex-end

.main-footer-player-controls
  --button-padding unit(8/16, rem)
  align-items center
  display flex
  font-size unit(18/16, rem)
  gap unit(24/16, rem)
  line-height 1

.main-footer-player-controls__button
  display block
  margin calc(var(--button-padding) * -1)
  padding var(--button-padding)

// MARK: .main-footer-player-info
.main-footer-player-info
  display flex
  gap var(--def-margin)
  min-height 100%

.main-footer-player-info__caption
  align-items flex-start
  display flex
  flex-direction column
  gap unit(6/16, rem)
  justify-content center
  line-height 1

.main-footer-player-info__image
  max-height var(--button-font-size)
  width auto

.main-footer-player-info__media
  display grid
  place-items center

.main-footer-player-info__status
  background-color var(--clr-black-alpha)
  border-radius 100vmax
  font-size unit(12/16, rem)
  font-weight 500
  padding-block unit(4/16, rem)
  padding-inline unit(12/16, rem)

.main-footer-player-info__title
  font-weight 500

// MARK: .main-footer-wrapper
.main-footer-wrapper
  --main-clr-bg var(--clr-red-200)
  --main-clr-fg var(--clr-white)
  background-color var(--main-clr-bg)
  color var(--main-clr-fg)

.main-footer-wrapper__button
  --main-clr-bg var(--clr-black-alpha)
  --main-clr-fg currentColor
  background-color var(--main-clr-bg)
  color var(--main-clr-fg)
  font-size unit(24/16, rem)
  font-weight 500
  line-height 1
  padding-block unit(8/16, rem)
  padding-inline unit(12/16, rem)
  transition var(--def-transition)
  transition-property background-color

.main-footer-wrapper__button:is(:hover, :focus-visible)
  --main-clr-bg var(--clr-black-alpha-hover)

.main-footer-wrapper__item--2
  display flex
  flex-direction column
  gap unit(12/16, rem)
  justify-content center

.main-footer-wrapper__container
  display grid
  gap var(--def-margin)
  grid-template-columns 1fr auto

.main-footer-wrapper__permalink
  font-size unit(20/16, rem)
  line-height 1

// MARK: .main-footer-wrapper-logo
.main-footer-wrapper-logo
  display inline-block

// MARK: .main-footer-wrapper-sections
.main-footer-wrapper-sections
  --main-gap unit(52/16, rem)
  column-gap var(--main-gap)
  display flex
  flex-wrap wrap
  font-size unit(18/16, rem)
  line-height 1.2
  row-gap var(--def-margin)
  text-align center

.main-footer-wrapper-sections__item
  display flex
  flex-direction column
  gap unit(12/16, rem)

.main-footer-wrapper-sections__list
  display inherit
  flex-direction inherit
  font-weight 300
  gap inherit

// MARK: .main-footer-wrapper-socials
.main-footer-wrapper-socials
  align-items center
  display flex
  flex-wrap wrap
  font-size unit(20/16, rem)
  gap calc(var(--def-margin) * 1.25)