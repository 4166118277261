.the-pagination
  --main-clr-bg var(--clr-gray-100)
  --main-clr-bg--active var(--clr-red-200)
  --main-clr-fg currentColor
  --main-clr-fg--active var(--clr-white)
  --main-font-size unit(24/16, rem)
  --main-gap var(--def-margin)
  align-items center
  display flex
  font-size var(--main-font-size)
  gap var(--main-gap)
  justify-content center
  line-height 1
  +until($MOBILE_SIZE)
    --main-font-size unit(18/16, rem)
    --main-gap calc(var(--def-margin) * .75)

.the-pagination__item
  background-color var(--main-clr-bg)
  color var(--main-clr-fg)
  padding .4em .6em
  transition var(--def-transition)
  transition-property background-color, color

.the-pagination__item:is(.is-current, :hover, :focus-visible)
  background-color var(--main-clr-bg--active)
  color var(--main-clr-fg--active)

.the-pagination__item:is(.is-current)
  pointer-events none
