._row
  --mobile-column-gap $mobile-column-gap
  --mobile-row-gap $mobile-column-gap

  --desktop-column-gap $desktop-column-gap
  --desktop-row-gap $desktop-column-gap

  --_column-gap var(--column-gap, var(--mobile-column-gap))
  --_row-gap var(--row-gap, var(--mobile-row-gap))

  column-gap var(--_column-gap)
  display grid
  grid-template-columns "repeat(%s, 1fr)" % $columns
  row-gap var(--_row-gap)
  +from($DESKTOP_SIZE)
    --column-gap var(--desktop-column-gap)
    --row-gap var(--desktop-row-gap)

:is(*[class^="_col-"], *[class*=" _col-"])
  grid-column-end span $columns
  min-width 0

for num in (1..$columns)
  ._col-end-{num}
    grid-column-end num + 1
  ._col-start-{num}
    grid-column-start num

for $name, $size in $breakpoints
  .show-in-{$name}
    display block
    @media screen and (min-width $size + 1)
      display none

  .hide-in-{$name}
    display none

  @media screen and (min-width $size)
    for $num in (1..$columns)
      ._col-{$name}-{$num}
        grid-column-end span $num
      ._col-{$name}-offset-{$num}
        grid-column-start $num