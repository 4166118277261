.main-card
  --bag-clr-bg var(--clr-red-100)
  --bag-clr-fg var(--clr-white)
  --bag-font-size unit(16/16, rem)
  --bag-padding-block unit(8/16, rem)
  --bag-padding-inline unit(20/16, rem)
  --caption-inline-padding unset
  --img-aspect-ratio 330/186
  --main-gap unit(12/16, rem)
  --title-font-size unit(22/16, rem)
  --title-line-height 1.31
  display flex
  flex-direction column
  gap var(--main-gap)

.main-card--400
  --bag-font-size unit(18/16, rem)
  --title-font-size unit(30/16, rem)

.main-card--300
  --title-font-size unit(24/16, rem)

.main-card--200
  --bag-font-size unit(16/16, rem)
  --title-font-size unit(22/16, rem)

.main-card--100
  --bag-font-size unit(14/16, rem)
  --title-font-size unit(20/16, rem)

.main-card--50
  --bag-font-size unit(14/16, rem)
  --bag-padding-inline unit(16/16, rem)
  --title-font-size unit(18/16, rem)

.main-card--lateral
  --caption-inline-padding unit(16/16, rem)
  --img-width unit(166/16, rem)
  --main-gap unset
  display grid
  grid-template-columns var(--img-width) 1fr

.main-card--align-center
  align-items center

.main-card__bag
  align-self start
  background-color var(--bag-clr-bg)
  color var(--bag-clr-fg)
  font-size var(--bag-font-size)
  font-weight 700
  grid-area stack
  justify-self start
  line-height 1
  padding-block var(--bag-padding-block)
  padding-inline var(--bag-padding-inline)
  position relative
  z-index 10

.main-card__caption
  padding-inline var(--caption-inline-padding)

.main-card__image
  aspect-ratio var(--img-aspect-ratio)
  height auto
  object-fit cover
  object-position var(--def-obj-pos)
  width 100%

.main-card__media
  grid-area stack

.main-card__title
  font-size var(--title-font-size)
  line-height var(--title-line-height)

.main-card__wrapper
  display grid
  grid-template-areas 'stack'
  position relative