$number-ratio = 1.094
$font-size    = DEFAULT_SIZE

:root
  --def-heading-fs unit(16/16, rem)
  --def-heading-ratio $number-ratio
  for $num in (6...0)
    if $num == 6
      --def-fs-h{$num} calc(var(--def-heading-fs) * var(--def-heading-ratio))
    else
      --def-fs-h{$num} "calc(var(--def-fs-h%s) * var(--def-heading-ratio))" % ($num + 1)

for $num in (6...0)
  h{$num}
    font-size "var(--def-fs-h%s)" % $num

:where(h1, h2, h3, h4, h5, h6)
  line-height inherit
  margin-block unset