.main-author-card
  --image-brd-width unit(1/16, rem)
  --image-width unit(55/16, rem)
  --main-clr-fg @css { hsl(0, 0%, 44%) }
  --main-font-size unit(18/16, rem)
  --main-gap unit(10/16, rem)
  --permalink-clr-fg var(--clr-red-100)
  align-items center
  color var(--main-clr-fg)
  display grid
  font-size var(--main-font-size)
  gap var(--main-gap)
  grid-template-columns var(--image-width) 1fr
  line-height 1.3

.main-author-card__media
  border var(--image-brd-width) solid
  border-radius 50%
  overflow hidden

.main-author-card__permalink
  color var(--permalink-clr-fg)
  font-weight 700

.main-author-card__permalink:is(:hover)
  text-decoration underline