.swiper:not(.swiper-initialized)
  display none

:where(.swiper-pagination)
  align-items center
  display flex
  flex-wrap wrap
  gap unit(12/16, rem)
  justify-content center

:where(.swiper-pagination .swiper-pagination-bullet)
  background-color var(--clr-white)
  border-radius 50%
  cursor pointer
  opacity .3
  size unit(16/16, rem)
  transition var(--def-transition)
  transition-property opacity

:where(.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active)
  opacity 1
  pointer-events none