// MARK: .author-card
.author-card
  --image-width unit(233/16, rem)
  --main-clr-bg var(--clr-opinion)
  --main-clr-fg var(--clr-white)
  --main-padding-block unit(40/16, rem) unit(16/16, rem)
  --main-padding-inline unit(28/16, rem)
  --text-font-size unit(20/16, rem)
  --text-font-weight 400
  --text-line-height (26/20)
  --title-font-size unit(32/16, rem)
  --title-font-weight 700
  --title-line-height (43/32)
  --icon-font-size unit(22/16, rem)
  --url-font-size unit(20/16, rem)
  --url-font-weight 600
  --url-line-height (26/20)
  background-color var(--main-clr-bg)
  color var(--main-clr-fg)
  display flex
  flex-direction column
  padding-block var(--main-padding-block)
  padding-inline var(--main-padding-inline)

.author-card__media
  border-radius 50%
  margin-inline auto
  max-width var(--image-width)
  overflow hidden

.author-card__text
  font-size var(--text-font-size)
  font-weight var(--text-font-weight)
  line-height var(--text-line-height)
  margin-block unset

.author-card__title
  display block
  font-size var(--title-font-size)
  font-weight var(--title-font-weight)
  line-height var(--title-line-height)
  text-align center

.author-card__url
  text-align center
  display block
  font-size var(--url-font-size)
  font-weight var(--url-font-weight)
  line-height var(--url-line-height)

.author-card__url:is(:hover)
  text-decoration underline

// MARK: .author-card-list
.author-card-list
  display flex
  flex-wrap wrap
  align-items center
  font-size var(--icon-font-size)
  gap 1.2ch