.u-clearfix
  clearfix()

:where(.u-container)
  margin-inline auto
  padding-inline var(--def-margin)
  width @css { min(100%, calc(var(--def-width) + (var(--def-margin) * 2))) }

for $num in (1..10)
  .u-dots-{$num}
    -dots($num)

.u-float-left
  float left !important

.u-float-right
  float right !important

for $font-weight in range(100, 900, 100)
  .u-fw-{$font-weight}
    font-weight $font-weight !important

.u-full-width
  left 50%
  margin-inline calc((var(--vw, 100vw) / 2) * -1)
  position relative
  right 50%
  width var(--vw, 100vw)

.u-hidden
  display none !important

.u-invisible
  visibility hidden !important

.u-negative-margin
  margin-inline calc(var(--def-margin) * -1)

.u-negative-margin-start
  margin-inline-start calc(var(--def-margin) * -1)

.u-negative-margin-end
  margin-inline-end calc(var(--def-margin) * -1)

.u-overflow-hidden
  overflow hidden !important

[class*="u-responsive-image"]
  display block
  height auto
  max-width 100%

.u-responsive-image--full
  width 100%

.u-responsive-video
  height 0
  overflow hidden
  padding-bottom 56.25%
  position relative

:is(.u-responsive-video embed, .u-responsive-video iframe, .u-responsive-video object, .u-responsive-video video)
  border 0
  inset 0
  position absolute

.u-text-center
  text-align center !important

.u-text-ellipsis
  ellipsis()

.u-text-align-start
  text-align start !important

.u-text-align-end
  text-align end !important

.u-text-underline
  text-decoration underline !important

.u-text-uppercase
  text-transform uppercase !important

.u-the-banner
  align-items center
  display flex
  justify-content center
  // overflow hidden

.u-the-banner:has(> [id^="ad_"]:empty)
  display none !important

.u-disable-effects
  & *
    transition none !important
  .main
    overflow hidden

.u-flex-align-start
  align-items flex-start !important

.u-flex-align-end
  align-items flex-end !important

.u-lateral-menu
  align-items flex-start
  display inline-flex
  flex-wrap wrap

.u-reset-menu
  list-style none
  margin-block unset
  padding-inline-start initial

.u-clr-bg
  --main-clr-bg var(--clr-black)
  --main-clr-fg var(--clr-white)
  background-color var(--main-clr-bg)
  color var(--main-clr-fg)

.u-img[style]
  --width var(--mobile-width)
  height auto
  width var(--width)
  +from($DESKTOP_SIZE)
    --width var(--desktop-width, var(--mobile-width))

.u-svg[style]
  aspect-ratio 1
  display grid
  height auto
  place-items center
  width var(--size, auto)

.u-hover\\:text-decoration:hover
  text-decoration underline

:where(.u-reset-button)
  background-color unset
  border unset
  color currentColor
  padding unset

.u-ff-sans
  font-family var(--alt-font-family)

:where(.u-aspect-ratio)
  --aspect-ratio 16/9
  aspect-ratio var(--aspect-ratio)
  display block
  height auto
  width 100%
  object-fit cover
  object-position var(--def-obj-pos)

.u-separator
  --clr-bg var(--clr-red-100)
  --size unit(2/16, rem)
  background-color var(--clr-bg)
  height var(--size)
  pointer-events none

.u-separator--current-color
  --clr-bg currentColor

.u-separator--thin
  --clr-bg @css { hsl(0, 0%, 44%) }
  --size unit(1/16, rem)

.u-clr-fg
  color var(--clr-fg, var(--clr-red-100))

.u-media
  --main-clr-bg @css { color-mix(in srgb, var(--main-clr-fg), transparent 50%) }
  --main-clr-fg var(--clr-white)
  --main-font-size unit(32/16, rem)
  --main-inset 0
  --main-margin auto
  --main-size 2.075em
  isolation isolate
  overflow hidden
  position relative

.u-media--alt
  --main-inset auto unit(12/16, rem) unit(12/16, rem) auto

.u-media--medium
  --main-font-size unit(28/16, rem)

.u-media__icon
  aspect-ratio 1
  background-color var(--main-clr-bg)
  border-radius 50%
  color var(--main-clr-fg)
  display grid
  font-size var(--main-font-size)
  height auto
  inset var(--main-inset)
  margin var(--main-margin)
  place-items center
  pointer-events none
  position absolute
  width var(--main-size)
  z-index 10

-set-gutter()