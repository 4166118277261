.default-form
  --input-clr-border @css { hsl(0, 0%, 0%, .3) }
  --input-clr-fg @css { hsl(0, 0%, 0%, .6) }
  --main-font-size unit(20/16, rem)
  align-items flex-start
  display flex
  flex-direction column
  font-size var(--main-font-size)

.default-form__group
  align-items flex-start
  display flex
  flex-direction column
  gap unit(12/16, rem)
  line-height 1
  width 100%

.default-form__input
  border 1px solid var(--input-clr-border)
  color var(--input-clr-fg)
  padding .4em
  transition var(--def-transition)
  transition-property border-color
  width 100%

.default-form__input:is(:focus, :focus-visible)
  border-color var(--clr-red-300)

.default-form__submit
  align-self flex-end