-font-face($name, $file, $weight = 400, $style = normal, $stretch = normal, $display = swap)
  @font-face
    font-family $name
    src url($fonts $file".woff2") format("woff2"),
    url($fonts $file".woff") format("woff")
    font-weight $weight
    font-stretch $stretch
    font-display $display
    font-style $style

-placeholder($col, $important = false)
  $important = $important ? !important : unquote("")
  for vendor in ':-webkit-input' ':-moz' '-moz' '-ms-input'
    &:{vendor}-placeholder
      color $col $important
      opacity 1

-hardware($backface = true, $perspective = 1000)
  if $backface
    backface-visibility hidden
  perspective $perspective

-absolute-center()
  absolute top 50% left 50%
  transform translate(-50%, -50%)

-dots($lines = 3)
  display -webkit-box
  -webkit-box-orient vertical
  -webkit-line-clamp $lines
  overflow hidden

-set-gutter($name = 'all', $min = $space-range.min, $max = $space-range.max, $incr = $space-range.incr)
  for $num in range($min, $max, $incr)
    // MARGINS
    .{$name}\\:margin-block-start-{$num}
      margin-block-start unit($num / DEFAULT_SIZE, rem)

    .{$name}\\:margin-inline-end-{$num}
      margin-inline-end unit($num / DEFAULT_SIZE, rem)

    .{$name}\\:margin-block-end-{$num}
      margin-block-end unit($num / DEFAULT_SIZE, rem)

    .{$name}\\:margin-inline-start-{$num}
      margin-inline-start unit($num / DEFAULT_SIZE, rem)

    .{$name}\\:margin-block-{$num}
      margin-block unit($num / DEFAULT_SIZE, rem)

    .{$name}\\:margin-inline-{$num}
      margin-inline unit($num / DEFAULT_SIZE, rem)

    if $num >= 0
      // PADDINGS
      .{$name}\\:padding-block-start-{$num}
        padding-block-start unit($num / DEFAULT_SIZE, rem)

      .{$name}\\:padding-inline-end-{$num}
        padding-inline-end unit($num / DEFAULT_SIZE, rem)

      .{$name}\\:padding-block-end-{$num}
        padding-block-end unit($num / DEFAULT_SIZE, rem)

      .{$name}\\:padding-inline-start-{$num}
        padding-inline-start unit($num / DEFAULT_SIZE, rem)

      .{$name}\\:padding-block-{$num}
        padding-block unit($num / DEFAULT_SIZE, rem)

      .{$name}\\:padding-inline-{$num}
        padding-inline unit($num / DEFAULT_SIZE, rem)