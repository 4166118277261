.the-header
  --main-border unit(6/16, rem)
  --main-clr-fg var(--clr-red-200)
  --main-padding var(--main-border)
  --permalink-font-size unit(20/16, rem)
  --permalink-font-weight 800
  --title-font-size unit(35/16, rem)
  --title-font-weight 800
  align-items center
  color var(--main-clr-fg)
  display flex
  gap var(--def-margin)
  line-height 1

.the-header--border
  border-block-end var(--main-border) solid
  padding-block-end var(--main-padding)

.the-header--half-border
  --main-border unit(3/16, rem)
  border-block-end var(--main-border) solid
  padding-block-end var(--main-padding)

.the-header--alt-border
  border-block-end var(--main-border) solid
  padding-block calc(var(--main-padding) / 2)

.the-header--medium
  --main-padding unit(16/16, rem)
  --title-font-size unit(25/16, rem)

.the-header--black
  --title-font-weight 900

.the-header--fg-inherit
  --main-clr-fg inherit

.the-header__permalink
  font-size var(--permalink-font-size)
  font-weight var(--permalink-font-weight)
  margin-inline-start auto

.the-header__title
  font-size var(--title-font-size)
  font-weight var(--title-font-weight)