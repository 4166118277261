// MOBILE DEVICES WITH VERTICAL ORIENTATION
// +portrait()

// MOBILE DEVICES WITH HORIZONTAL ORIENTATION
// +landscape()

// UNTIL
// +until(980px)
// +until(980px, 'height')

// FROM
// +from(980px)
// +from(980px, 'height')

// BETWEEN
// +between(100px, 200px)
// +between(100px, 200px, 'height')

// RETINA
// retina()

// * BREAKPOINTS
// edit BREAKPOINTS IN ./dev/includes/stylus/base/_vars.styl

// ** DEFAULT VALUES
// $breakpoints.x-sm
// $breakpoints.sm
// $breakpoints.md
// $breakpoints.lg
// $breakpoints.x-lg

// DESKTOP
+from($DESKTOP_SIZE)
  -set-gutter('desktop')

  .u-hide-in-desktop
    display none !important

+between($DESKTOP_SIZE, 1239px)
  html
    font-size clamp(12px, 1.25vw, 16px)

// MOBILE
+until($MOBILE_SIZE)
  -set-gutter('mobile')

  .u-hide-in-mobile
    display none !important

  .u-mobile-negative-margin
    margin-inline calc(var(--def-margin) * -1)

  .u-mobile-negative-margin-start
    margin-inline-start calc(var(--def-margin) * -1)

  .u-mobile-negative-margin-end
    margin-inline-end calc(var(--def-margin) * -1)

  .main-header-group
    --main-gap unit(20/16, rem)

  .main-footer-player
    --main-padding-inline var(--def-margin)

  .main-footer-player-controls
    --button-padding unit(6/16, rem)
    gap unit(16/16, rem)

  .main-footer-wrapper__container
    grid-template-columns 1fr

  .main-footer-player-info__media
    display none

  .main-footer-player-info__caption
    flex-direction column-reverse

  .main-footer-player-info__status
    font-size unit(10/16, rem)

  .main-footer-player-info__title
    font-size unit(12/16, rem)
    ellipsis()
    width 100%

  .main-footer-wrapper-logo
    display table
    margin-inline auto

  .main-footer-wrapper-socials
    justify-content center
    font-size unit(30/16, rem)

  .main-footer-wrapper-sections
    text-align left
    display grid
    grid-template-columns repeat(2, 1fr)
    gap calc(var(--def-margin) * 2)

  .main-footer-wrapper__item--2
    align-items center

  .main-footer-wrapper__button
    min-width unit(266/16, rem)

  .main-card--400
    --title-font-size unit(24/16, rem)

  .the-header
    --title-font-size unit(30/16, rem)
    --permalink-font-size unit(16/16, rem)

  .the-header--medium
    --title-font-size unit(24/16, rem)
    --main-border unit(3/16, rem)
    --main-padding unit(12/16, rem)

  .user-card
    gap unit(24/16, rem)

  .tv-card
    --main-font-size unit(16/16, rem)
    --title-font-size unit(24/16, rem)

  .main-media-box
    --wrapper-size unit(248/16, rem)

  .main-layout
    --main-columns 1fr
    gap calc(var(--def-margin) * 1.25)

  .main-article
    --title-font-size unit(30/16, rem)
    --text-font-size unit(18/16, rem)
    --excerpt-font-size unit(18/16, rem)

  .main-article-box
    --card-columns 1fr
    --title-font-size unit(24/16, rem)
    --card-gap calc(var(--def-margin) * .75)

  .main-article-box__title
    display block
    border-bottom unit(3/16, rem) solid
    padding-block-end unit(8/16, rem)

  .main-article-tags
    --title-font-size unit(24/16, rem)

  .main-article-socials
    justify-content flex-start

  .the-title
    --main-font-size unit(24/16, rem)

  .author-card-list
    justify-content center

  :root
    --def-heading-ratio 1.0871