:where(.the-button)
  --main-border-radius unset
  --main-clr-bg var(--clr-red-200)
  --main-clr-bg--hover @css { color-mix(in srgb, var(--main-clr-bg), var(--clr-black) 20%) }
  --main-clr-fg var(--clr-white)
  --main-font-size unit(24/16, rem)
  --main-font-weight 500
  --main-padding-block unit(3/24, em)
  --main-padding-inline unit(12/24, em)
  background-color var(--main-clr-bg)
  border unset
  color var(--main-clr-fg)
  cursor pointer
  display inline-flex
  font-size var(--main-font-size)
  font-weight var(--main-font-weight)
  padding-block var(--main-padding-block)
  padding-inline var(--main-padding-inline)
  transition var(--def-transition)
  transition-property background-color

.the-button:active
  scale 0.98

.the-button:is(:hover, :focus-visible)
  background-color var(--main-clr-bg--hover)