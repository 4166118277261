.agricultura-tv-carousel
  --button-font-size unit(36/16, rem)
  --button-gradient var(--clr-agricultura-tv), @css { color-mix(in srgb, var(--clr-agricultura-tv), transparent 100%) }
  --button-width unit(98/16, rem)

.agricultura-tv-carousel__arrow
  bottom 0
  display grid
  font-size var(--button-font-size)
  place-items center
  pointer-events none
  position absolute
  top 0
  width var(--button-width)
  z-index 10

.agricultura-tv-carousel__arrow.swiper-button-disabled::before
  opacity .25
  pointer-events none

.agricultura-tv-carousel__arrow::before
  cursor pointer
  pointer-events auto
  transition var(--def-transition)
  transition-property opacity

.agricultura-tv-carousel__arrow--prev
  background-image linear-gradient(to right, var(--button-gradient))
  left 0

.agricultura-tv-carousel__arrow--next
  background-image linear-gradient(to left, var(--button-gradient))
  right 0

+until(767px)
  .agricultura-tv-carousel__slide
    max-width unit(206/16, rem)