$reset
  border unset
  margin unset
  padding unset

$horizontal-scrolled
  display flex
  justify-content flex-start
  overflow-x auto
  white-space nowrap
  &__item
    flex 0 0 auto