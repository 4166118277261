@import "../config.styl"

*
*::after
*::before
  box-sizing border-box
  min-width 0

*:focus
  outline 0

::selection
  background var(--def-selection-bg)
  color var(--def-selection-fg)
  text-shadow none

:where(body, html)
  min-height 100%

:where(body)
  -webkit-font-smoothing antialiased
  background-color var(--def-body-bg)
  color var(--def-text-color)
  font-family var(--def-font-family)
  margin unset
  position relative

:where(html)
  line-height var(--def-line-height)
  scroll-behavior smooth

:where(a)
  color inherit
  cursor pointer
  text-decoration none

:where(img, picture, video, canvas, svg)
  display block
  max-width 100%

:where(img)
  height auto

:where(figure, fieldset)
  @extends $reset

:where(hr)
  background-color @css { hsl(0, 0%, 80%) }
  border unset
  display block
  height unit(1/16, rem)
  margin unset
  padding unset

:where(mark)
  background transparent
  color inherit

:where(button, input, select, textarea)
  font inherit

:where(textarea)
  resize vertical

:where(p, h1, h2, h3, h4, h5, h6)
  overflow-wrap break-word

// :where(h1, h2, h3, h4, h5, h6)
//   text-wrap balance

:where(p)
  text-wrap pretty

iframe
  border 0

iframe[src=""]
  display none

:target
  scroll-margin-top calc(var(--main-header-height, auto) + var(--def-margin))

if IS_ENABLED_PARAGRAPH_HYPENS
  :where(p)
    hyphens auto