.tv-card
  --img-aspect-ratio 311/277
  --main-font-size unit(20/16, rem)
  --title-font-size unit(34/16, rem)
  display flex
  flex-direction column
  font-size var(--main-font-size)
  gap unit(16/16, rem)

.tv-card__caption
  align-items center
  display flex
  flex-direction column
  text-align center

.tv-card__image
  aspect-ratio var(--img-aspect-ratio)
  display block
  height auto
  object-fit cover
  object-position var(--def-obj-pos)
  width 100%

.tv-card__media
  display block

.tv-card__subtitle
  font-weight 500

.tv-card__title
  font-size var(--title-font-size)
  font-weight 700
  line-height (45/34)